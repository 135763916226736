export default {
  ticketUrl: "https://itulan-1.ticketbutler.io/e/itu-lan-fall-2024/",
  socialMedia: {
    discord: {
      url: "https://discord.gg/nSFXWQN",
      description:
        "Discord is our primary platform for contacting the crew during the LAN.",
    },
    facebook: {
      url: "https://www.facebook.com/itulan",
      description:
        "Facebook is the main platform for announcements and event related information.",
    },
    instagram: {
      url: "https://www.instagram.com/itulan/",
      description: "We use Instagram to post images from the event.",
    },
    youtube: {
      url: "https://www.youtube.com/channel/UCa8UEVBGtOfykw6N9G6db8g",
      description:
        "YouTube is our new streaming platform, where videos related to LAN will reside.",
    },
  },
  cookieNotice:
    "Hey, GAMER! We use cookies, but ONLY to level up the user experience. By clicking 'Got it!' or using our site, you consent to the use of cookies unless you have disabled them.",
}